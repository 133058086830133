<template>
  <div class="pt-5 pb-5">
    <v-row>
      <v-col
        cols="12"
        sm="4"
        md="4"
      >
        <v-sheet width="100%">
          <v-slide-group
            v-model="frecuencias[pos].frecuencia"
            multiple
          >
            <v-slide-item
              v-for="(item, index) in semanas"
              v-slot="{ active, toggle }"
              :key="index"
              class="px-1"
            >
              <v-btn
                class=""
                :input-value="active"
                active-class="primary white--text"
                depressed
                rounded
                small
                @click="toggle"
              >
                {{ item }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        md="2"
        offset-md="2"
      >
        <v-menu
          v-model="menuFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFromDateFormatted"
              :label="$t('lbl.fromValidez')"
              persistent-hint
              readonly
              v-bind="attrs"
              outlined
              dense
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="frecuencias[pos].fromValidez"
            no-title
            :min="new Date(Date.now()).toISOString().substr(0, 10)"
            locale="es"
            :show-current="false"
            @input="
              menuFrom = false
              activeToDate()
            "
          ></v-date-picker>
        </v-menu>
        <!--<vc-date-picker
          v-model="frecuencias[pos].fromValidez"
          outlined
          dense
          :min-date="new Date()"
          mode="date"
          :model-config="modelConfig"
          @input="activeToDate"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              :label="$t('lbl.fromValidez')"
              outlined
              dense
              :value="inputValue"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>-->
      </v-col>
      <v-col
        cols="12"
        sm="2"
        md="2"
      >
        <v-menu
          v-model="menuTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedToDateFormatted"
              :label="$t('lbl.toValidez')"
              :disabled="!frecuencias[pos].fromValidez"
              persistent-hint
              readonly
              v-bind="attrs"
              outlined
              dense
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="frecuencias[pos].toValidez"
            no-title
            locale="es"
            :show-current="false"
            :min="
              frecuencias[pos].fromValidez
                ? $moment(frecuencias[pos].fromValidez)
                  .add(1, 'day')
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
                : ''
            "
            @input="menuTo = false"
          ></v-date-picker>
        </v-menu>
        <!--<vc-date-picker
          v-model="frecuencias[pos].toValidez"
          :disabled="!frecuencias[pos].fromValidez"
          outlined
          dense
          :min-date="
            $moment(frecuencias[pos].fromValidez)
              .add(1, 'day')
              .toDate()
          "
          mode="date"
          :model-config="modelConfig"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              ref="dateTo"
              :label="$t('lbl.toValidez')"
              outlined
              dense
              :value="inputValue"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>-->
      </v-col>
      <v-col
        cols="12"
        sm="1"
        md="1"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="clonarFreciencia()"
                >
                  <v-icon
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    {{ icons.mdiEyePlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.clone') }}</span>
            </v-tooltip>
          </v-col>
          <v-col
            v-if="$store.state.app.frecuencias.length > 1"
            cols="12"
            sm="4"
            md="4"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="error"
                  @click="deleteFrecuencia(pos)"
                >
                  <v-icon
                    v-bind="attrs"
                    small
                    v-on="on"
                  >
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.delete') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <!--ORIGEN-->
      <v-col
        cols="12"
        md="12"
      >
        <v-row no-gutters>
          <v-col
            cols="12"
            md="2"
          >
            <v-autocomplete
              v-model="frecuencias[pos].origen.destino"
              :items="itemsOrigen"
              :search-input.sync="searchOrigen"
              hide-details
              hide-selected
              :label="$t('lbl.origen')"
              outlined
              dense
              item-text="name"
              item-value="id"
              return-object
              :disabled="pos > 0"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('booking.origenSearch') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <span>{{ item.name !== undefined ? `${item.name.slice(0, 12)} ..` : '' }}</span>
              </template>
              <template v-slot:item="{ item }">
                <v-icon left>
                  {{ icons.mdiAirplaneTakeoff }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="ml-2"
          >
            <vc-date-picker
              v-model="frecuencias[pos].origen.salida"
              outlined
              dense
              mode="time"
              :model-config="modelTimeConfig"
              is24hr
            >
              <template v-slot="{ inputValue, inputEvents }">
                <v-text-field
                  :label="$t('lbl.departure')"
                  outlined
                  dense
                  :value="inputValue"
                  v-on="inputEvents"
                >
                </v-text-field>
              </template>
            </vc-date-picker>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            md="2"
            class="ml-2"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="frecuencias[pos].origen.terminal"
                  :label="$t('lbl.terminal')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="5"
                class="ml-3"
              >
                <v-text-field
                  v-model="frecuencias[pos].origen.puerta"
                  :label="$t('lbl.puerta')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="pos === 0"
            cols="12"
            md="2"
            class="ml-2"
          >
            <v-switch
              v-model="frecuencias[pos].tiene_escalas"
              :label="$t('lbl.escalas')"
              color="primary"
              class="mt-2"
              hide-details
              @change="changeEscalas"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>

      <!--ESCALAS-->
      <v-col
        v-if="frecuencias[pos].escalas.length > 0"
        cols="12"
        md="12"
      >
        <Escalas
          v-for="(escala, i) in frecuencias[pos].escalas"
          :key="i"
          :escala="escala"
          :pos="i"
          :pos-frecuencia="pos"
        />
      </v-col>

      <!--DESTINO-->
      <v-col
        cols="12"
        md="12"
      >
        <v-row no-gutters>
          <v-col
            cols="12"
            md="2"
          >
            <v-autocomplete
              v-model="frecuencias[pos].destino.destino"
              :items="itemsDestino"
              :search-input.sync="searchDestino"
              hide-details
              hide-selected
              :label="$t('lbl.destino')"
              outlined
              dense
              item-text="name"
              item-value="id"
              return-object
              :disabled="pos > 0"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('booking.destinoSearch') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <span>{{ item.name !== undefined ? `${item.name.slice(0, 12)} ...` : '' }}</span>
              </template>
              <template v-slot:item="{ item }">
                <v-icon left>
                  {{ icons.mdiAirplaneTakeoff }}
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="ml-2"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                md="7"
              >
                <vc-date-picker
                  v-model="frecuencias[pos].destino.llegada"
                  outlined
                  dense
                  mode="time"
                  :model-config="modelTimeConfig"
                  is24hr
                  @input="calculateLlegadaEscala(pos)"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      :label="$t('lbl.llegada')"
                      outlined
                      dense
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col
                cols="12"
                md="1"
                class="pt-2 pl-1"
              >
                +
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="frecuencias[pos].destino.days"
                  :label="$t('lbl.day')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  min="0"
                  @input="changeDaysCalculate(pos)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            md="2"
            class="ml-2"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="frecuencias[pos].destino.terminal"
                  :label="$t('lbl.terminal')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="5"
                class="ml-3"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-text-field
                      v-model="frecuencias[pos].destino.duration"
                      :label="$t('lbl.duration')"
                      outlined
                      dense
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-tooltip
                      slot="append-outer"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">
                            {{ icons.mdiInformationOutline }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('msg.durationHourMin') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="5"
            class="ml-2"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                sm="3"
                md="3"
                class="mt-2 ml-2 mr-2"
              >
                <strong>{{ $t('msg.timeFly') }}:</strong>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="1"
                    class="pt-2"
                  >
                    +
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="frecuencias[pos].days"
                      :label="$t('lbl.day')"
                      outlined
                      dense
                      hide-details
                      type="number"
                      min="0"
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="ml-2"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        md="10"
                      >
                        <v-text-field
                          v-model="frecuencias[pos].duration"
                          :label="$t('lbl.duration')"
                          outlined
                          dense
                          hide-details
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-tooltip
                          slot="append-outer"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="primary">
                                {{ icons.mdiInformationOutline }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('msg.durationTravelHourMin') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-row no-gutters>
          <v-col
            cols="12"
            md="12"
          >
            <v-icon
              right
              color="error"
            >
              {{ icons.mdiAlertOutline }}
            </v-icon>
            {{ $t('msg.hourAirport') }}
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <!--<v-row>
      <v-col
        cols="12"
        md="3"
      >
        !--<vc-date-picker
          v-model="check_in"
          outlined
          dense
          mode="time"
          :model-config="modelTimeConfig"
          is24hr
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              :label="$t('lbl.checkIn')"
              outlined
              dense
              :value="inputValue"
              v-on="inputEvents"
              @click="updateValue(inputValue)"
            >
            </v-text-field>
          </template>
        </vc-date-picker>--
        <v-menu
          ref="menu"
          v-model="departure"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="check_in"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="check_in"
              :label="$t('lbl.checkIn')"
              outlined
              dense
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="departure"
            v-model="check_in"
            full-width
            @click:minute="$refs.menu.save(check_in)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        !--<vc-date-picker
          v-model="frecuencia.hour_departure"
          outlined
          dense
          mode="time"
          :model-config="modelTimeConfig"
          is24hr
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              :label="$t('lbl.hour_departure')"
              outlined
              dense
              :value="inputValue"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>--
        <v-menu
          ref="menu1"
          v-model="hour_departure"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="frecuencia.hour_departure"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="frecuencia.hour_departure"
              :label="$t('lbl.hour_departure')"
              outlined
              dense
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="hour_departure"
            v-model="frecuencia.hour_departure"
            full-width
            @click:minute="$refs.menu1.save(frecuencia.hour_departure)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        md="2"
      >
        <v-text-field
          v-model="frecuencia.terminal_departure"
          :label="$t('lbl.terminal')"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        md="2"
      >
        <v-text-field
          v-model="frecuencia.puerta_departure"
          :label="$t('lbl.puerta')"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="3"
      >
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        !--<vc-date-picker
          v-model="frecuencia.hour_arrival"
          outlined
          dense
          mode="time"
          :model-config="modelTimeConfig"
          is24hr
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              :label="$t('lbl.hour_arrival')"
              outlined
              dense
              :value="inputValue"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>--
        <v-menu
          ref="menu2"
          v-model="hour_arrival"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="frecuencia.hour_arrival"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="frecuencia.hour_arrival"
              :label="$t('lbl.hour_arrival')"
              outlined
              dense
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="hour_arrival"
            v-model="frecuencia.hour_arrival"
            full-width
            @click:minute="$refs.menu2.save(frecuencia.hour_arrival)"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        md="2"
      >
        <v-text-field
          v-model="frecuencia.terminal_arrival"
          :label="$t('lbl.terminal')"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        md="2"
      >
        <v-text-field
          v-model="frecuencia.puerta_arrival"
          :label="$t('lbl.puerta')"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
      </v-col>
      <v-col
        cols="12"
        sm="2"
        md="2"
      >
        <v-text-field
          v-model="frecuencia.time_vuelo_h"
          :label="$t('lbl.duration')"
          outlined
          dense
          hide-details
          suffix="hour"
          type="number"
          min="0"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        md="2"
      >
        <v-text-field
          v-model="frecuencia.time_vuelo_m"
          :label="$t('lbl.duration')"
          outlined
          dense
          hide-details
          suffix="min"
          type="number"
          min="0"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-divider></v-divider>
      </v-col>
    </v-row>-->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiEyePlus,
  mdiInformationOutline,
  mdiAlertOutline,
} from '@mdi/js'

import Escalas from './Escalas.vue'

export default {
  components: {
    Escalas,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiEyePlus,
        mdiInformationOutline,
        mdiAlertOutline,
      },
      departure: false,
      hour_departure: false,
      hour_arrival: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      loading: false,

      items: [],
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,

      semanas: [],
      menuFrom: false,
      menuTo: false,
    }
  },
  computed: {
    ...mapState({
      frecuencias: state => state.app.frecuencias,
    }),
    computedFromDateFormatted() {
      return this.frecuencias[this.pos].fromValidez
        ? this.$moment(this.frecuencias[this.pos].fromValidez).format('D-MMM-YY')
        : ''
    },
    computedToDateFormatted() {
      return this.frecuencias[this.pos].toValidez
        ? this.$moment(this.frecuencias[this.pos].toValidez).format('D-MMM-YY')
        : ''
    },
  },
  watch: {
    /* check_in() {
      this.frecuencia.check_in = this.check_in

      this.frecuencia.hour_departure = this.$moment(this.frecuencia.check_in, 'HH:mm').add(3, 'hours').format('HH:mm')
    }, */

    searchOrigen(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOrigen(val.toLowerCase())
      } else {
        this.itemsOrigen = []
      }
    },
    searchDestino(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.itemsDestino = []
      }
    },
  },

  created() {
    this.semanas.push('D')
    this.semanas.push('L')
    this.semanas.push('M')
    this.semanas.push('X')
    this.semanas.push('J')
    this.semanas.push('V')
    this.semanas.push('S')

    if (this.frecuencias[this.pos].origen) {
      this.filterOrigen(this.frecuencias[this.pos].origen.name)
    }
    if (this.frecuencias[this.pos].destino) {
      this.filterDestino(this.frecuencias[this.pos].destino.name)
    }

    this.isLoading = true
  },

  methods: {
    ...mapMutations([
      'cloneFrecuencia',
      'addEscalaFrecuencia',
      'deleteAllEscalaFrecuencia',
      'deleteEscala',
      'updateEscala',
      'adicionarFrecuencia',
      'updateFrecuencia',
      'deleteFrecuencia',
      'calculateLlegadaEscala',
      'changeDaysCalculate',
    ]),
    activeToDate() {
      setTimeout(() => {
        this.menuTo = true

        // this.$refs.dateTo.focus()
      }, 100)
    },
    filterOrigen(v) {
      this.itemsOrigen = []
      if (v === '') {
        this.itemsOrigen = []
      } else {
        // this.itemsOrigen = this.items.filter(e => e.iataComodin.toLowerCase())
        this.axios
          .post(
            'airports-names-filter',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              // this.items.push({ id: element.id, name: `${element.nominatim.address.city !== undefined ? element.nominatim.address.city : (element.nominatim.address.state !== undefined ? element.nominatim.address.state : element.nominatim.address.island)} (${element.iata} - ${element.name}) ${element.nominatim.address.country}` })
              this.itemsOrigen.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        // this.itemsDestino = this.items.filter(e => e.iataComodin.toLowerCase())
        this.axios
          .post(
            'airports-names-filter',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              // this.items.push({ id: element.id, name: `${element.nominatim.address.city !== undefined ? element.nominatim.address.city : (element.nominatim.address.state !== undefined ? element.nominatim.address.state : element.nominatim.address.island)} (${element.iata} - ${element.name}) ${element.nominatim.address.country}` })
              this.itemsDestino.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },
    changeEscalas() {
      if (!this.frecuencias[this.pos].tiene_escalas) {
        this.deleteAllEscalaFrecuencia(this.pos)
      } else {
        this.addEscalaFrecuencia(this.pos)
      }
    },
    clonarFreciencia() {
      const { fromValidez } = this.frecuencias[this.pos]
      const { toValidez } = this.frecuencias[this.pos]
      const origen = {
        destino: this.frecuencias[this.pos].origen.destino,
        salida: this.frecuencias[this.pos].origen.salida,
        terminal: this.frecuencias[this.pos].origen.terminal,
        puerta: this.frecuencias[this.pos].origen.puerta,
        duration: this.frecuencias[this.pos].origen.duration,
        durationM: this.frecuencias[this.pos].origen.durationM,
      }
      const destino = {
        destino: this.frecuencias[this.pos].destino.destino,
        llegada: this.frecuencias[this.pos].destino.llegada,
        terminal: this.frecuencias[this.pos].destino.terminal,
        puerta: this.frecuencias[this.pos].destino.puerta,
        days: this.frecuencias[this.pos].destino.days,
        duration: this.frecuencias[this.pos].destino.duration,
        durationM: this.frecuencias[this.pos].destino.durationM,
      }
      const escalas = []
      this.frecuencias[this.pos].escalas.forEach(element => {
        escalas.push({
          city: element.city,
          llegada: element.llegada,
          llegadaDay: element.llegadaDay,
          llegadaTerminal: element.llegadaTerminal,
          llegadaDuration: element.llegadaDuration,
          salida: element.salida,
          salidaDay: element.salidaDay,
          salidaTerminal: element.salidaTerminal,
          salidaDuration: element.salidaDuration,
        })
      })
      // eslint-disable-next-line camelcase
      const { tiene_escalas } = this.frecuencias[this.pos]
      const frec = {
        frecuencia: [],
        fromValidez,
        toValidez,
        origen,
        destino,
        days: '',
        duration: '',
        durationM: 0,
        escalas,
        tiene_escalas,
      }
      this.cloneFrecuencia(frec)
    },

    /* calculateLlegadaEscala() {
      let beginningTime
      if (this.frecuencia.escalas.length === 0) {
        beginningTime = this.$moment(this.frecuencia.origen.salida, 'hh:mm')
      } else {
        beginningTime = this.$moment(this.frecuencia.escalas[this.frecuencia.escalas.length - 1].salida, 'hh:mm')
      }

      const endTime = this.$moment(this.frecuencia.destino.llegada, 'hh:mm')

      if (beginningTime.isBefore(endTime)) {
        this.frecuencia.destino.days = 0
      } else {
        this.frecuencia.destino.days = 1
      }

      let days = 0
      this.frecuencia.escalas.forEach(element => {
        days = days + element.llegadaDay + element.salidaDay
      })
      this.frecuencia.days = days + this.frecuencia.destino.days
    }, */
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
